type RoutesBasicValues =
  | 'home'
  | 'services'
  | 'clients'
  | 'products'
  | 'opensource'
  | 'blog'
  | 'authors'
  | 'landings'
  | 'devtools'
  | 'forStartups'
  | 'railsStartupStack'
  | 'events'
  | 'privacy'
  | 'cookies'
  | 'noticeAtCollection'
  | 'blogFeed'
  | 'eventsFeed'
  | 'rssFeed'
  | 'devPropulsionLabs'
  | 'contactUs';

const routesBasic: {
  [key in RoutesBasicValues]: string;
} = {
  home: '/',
  services: '/services',
  clients: '/clients',
  products: '/products',
  opensource: '/opensource',
  blog: '/chronicles',
  authors: '/martians',
  landings: '/landings',
  devtools: '/devtools',
  forStartups: '/for-startups',
  railsStartupStack: '/rails-startup-stack',
  devPropulsionLabs: '/devpropulsionlabs',
  events: '/events',
  privacy: '/privacy',
  cookies: '/cookies',
  noticeAtCollection: '/privacy#notice_at_collection',
  blogFeed: '/chronicles.atom',
  eventsFeed: '/events.atom',
  rssFeed: '/rss2email',
  contactUs: '/contact-us',
};

const slugRoute = (basicRoute: RoutesBasicValues) => (slug: string) =>
  `${routesBasic[basicRoute]}/${slug}`;

const routesSlug = {
  service: slugRoute('services'),
  client: slugRoute('clients'),
  product: slugRoute('products'),
  author: slugRoute('authors'),
  opensourceProject: slugRoute('opensource'),
  blogPost: slugRoute('blog'),
  landing: slugRoute('landings'),
  event: slugRoute('events'),
};

const routesAnchors = {
  contactUs: '#contact-us',
};

export const routes = {
  ...routesBasic,
  ...routesSlug,
  ...routesAnchors,
};

export type RoutesWithSlug = keyof typeof routesSlug;

export const navigation = [
  { label: 'Services', url: routesBasic.services },
  { label: 'Clients', url: routesBasic.clients },
  { label: 'Products', url: routesBasic.products },
  { label: 'Open Source', url: routesBasic.opensource },
  { label: 'Blog', url: routesBasic.blog },
  { label: 'Events', url: routesBasic.events },
  { label: 'Podcast', url: routesBasic.devPropulsionLabs },
  { label: 'Careers', url: 'https://wellfound.com/company/evilmartians' },
];
